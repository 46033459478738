<!--
 * @Author: niweixing
 * @Date: 2022-11-27 02:05:24
 * @LastEditors: niweixing
 * @LastEditTime: 2023-04-02 12:05:45
 * @Description: 
-->
<template>
  <div>
    <div class="tu-container-title">
        <h2 class="page-title">动态</h2>
    </div>

    <div>
        <v-container>
        <v-tabs v-model="tab">
          <v-tab>
            <div>全部动态</div>
          </v-tab>

          <!-- <v-tab>
            <div>与我有关</div>
          </v-tab> -->

        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <AllDynamic :selObj="selObj" />
          </v-tab-item>
          <v-tab-item>
            <AboutMeDynamic  :selObj="selObj" />
          </v-tab-item>
          

        </v-tabs-items>
				
      </v-container>
    </div>

  </div>
</template>
<script>

export default {
  name: "DynamicPage",
  components: {
    AllDynamic: () => import('@/components/dynamic/AllDynamic.vue'),
    AboutMeDynamic: () => import('@/components/dynamic/AboutMeDynamic.vue'),
  },
  data(){
      return{
          tab: null,
          selObj: {
            type: 1,
            tab: 1
        },

      }
  }
};
</script>
<style scoped>
.tu-container-title {
    background-color: #f4f6f9;
    padding: 20px;
}
.page-title{
    font-size: 20px!important;
    font-weight: 700!important;
    color: #141C28;
}
</style>